import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Confetti from "react-dom-confetti";

import { Layout } from "../../components";

const config = {
  angle: 90,
  spread: 100,
  startVelocity: 60,
  elementCount: 150,
  dragFriction: 0.1,
  duration: 2500,
  stagger: 0,
  width: "7px",
  height: "10px",
  colors: [
    "#E68F17",
    "#FAB005",
    "#FA5252",
    "#E64980",
    "#BE4BDB",
    "#0B7285",
    "#15AABF",
    "#EE1233",
    "#40C057",
  ],
};

const BriefCampaignThankYou = ({ pageContext: { slug } }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowConfetti(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Layout
      seo={{
        title: "Brief light confirmation",
        href: slug,
        lang: "en",
        noIndex: true,
      }}
    >
      <div className="page_thanks">
        <img
          className="img-fluid"
          src={require("../../assets/img/brief_thank_you.png")}
          alt=""
        />
        <p className="desc">
          We will get to know your expectations as soon as possible. We will
          choose the right tools in order to achieve the goals that you set for
          us in the completed brief. You will hear from us very soon!
        </p>
        <p>
          <Link to="/en/" className="btn_custom">
            Home Page
          </Link>
        </p>
        <Confetti active={showConfetti} config={config} className="confetti" />
      </div>
    </Layout>
  );
};

export default BriefCampaignThankYou;
